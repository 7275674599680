import { WaterMark } from '@/components';
import { useDetectUpdates, useInitialState } from '@/hooks';
import { useEventEmitter, useLocalStorageState } from 'ahooks';
import { Image, Modal, Result } from 'antd';
import { useEffect, useState } from 'react';

type GlobalProps = {
  children: React.ReactNode;
};
const Global = (props: GlobalProps) => {
  useDetectUpdates();
  const [passwordExpire] = useLocalStorageState<string>('passwordExpire');
  useEffect(() => {
    if (passwordExpire) {
      Modal.warning({
        title: '您的密码要过期了，请尽快修改密码',
        onOk: () => {
          localStorage.removeItem('passwordExpire');
        },
      });
    }
  }, [passwordExpire]);
  const { currentUser } = useInitialState(({ currentUser }) => ({
    currentUser,
  }));
  window.event$ = useEventEmitter<{ type: string; payload?: any }>();
  const [src, setSrc] = useState();
  window.event$.useSubscription(({ type, payload }) => {
    if (type === 'previewImage') {
      setSrc(payload);
    }
  });
  if (currentUser?.orgStatus === 1) {
    return <Result title={'当前账号授权已过期，请联系管理员'} />
  }
  return (
    <>
      {props.children}
      <WaterMark
        gapX={200}
        gapY={200}
        rotate={-40}
        fontSize={18}
        content={currentUser?.nickname}
      />
      <Image
        style={{ display: 'none' }}
        rootClassName={'block'}
        preview={{
          src,
          visible: !!src,
          onVisibleChange: (value) => {
            if (!value) setSrc(undefined);
          },
        }}
      />
    </>
  );
};

export default Global;
