import Api from '@/services/api';
import { isEmpty, reduce } from 'lodash-es';

const toAccess = (menus?: API.SystemPolicyVo[]) => {
  return reduce(
    menus,
    (result: any, item: any) => {
      result[item.name] = true;
      return result;
    },
    {},
  );
};

const fetchUserInfo = async (setState, getState) => {
  const { onLogout } = getState();
  try {
    const response = await Api.yonghuzhongxin.getOauthUserCurrentInfo({
      skipErrorHandler: true,
    });
    if (isEmpty(response)) return;
    const { roles, menus, org, member_id, user_id, user, login_role } =
      response;
    const orgType = org?.type;
    const orgName = org?.name;
    const orgId = org?.id;
    const orgCode = org?.code;
    const currentUser = {
      orgType,
      orgName,
      orgId,
      orgCode,
      orgStatus: org?.status,
      userId: user_id,
      memberId: member_id,
      loginRole: login_role,
      sex: user?.sex,
      email: user?.email,
      mobile: user?.mobile,
      username: user?.username,
      nickname: user?.nickname,
      avatarId: user?.avatar_id,
      avatarSrc: user?.avatar_url,
      systemRole: user?.system_role,
    };
    window.orgType = orgType;
    window.memberId = member_id;
    const partial = {
      roles,
      orgId,
      orgCode,
      orgType,
      orgName,
      currentUser,
      access: toAccess(menus),
    };
    setState(partial);
    return partial;
  } catch (error) {
    onLogout();
  }
};
export default fetchUserInfo;
